<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-08-06 15:26 -->
<!-- Description:  -->
<template>
  <div>
    <legend>Archivos</legend>
    <div class="row">
      <div class="col-md-3" v-for="l in list" :key="l.id">
          <div class="card mb-4 shadow-sm">
            <img :src="l.file_path ? `${server_url}storage/${l.file_path}` : 'img/no-image.png'" alt="archivo"  width="100%">
            <div class="card-body">
              <p class="card-text">{{ l.type }}</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button type="button" class="btn btn-sm btn-outline-secondary"
                    @click="$refs.elModalUpload.show(); $refs.elPatientForm.setValue(l);">{{ l.state }}</button>
                </div>
              </div>
                <small class="text-muted">{{ l.updated_at | dateTimeFormat }}</small>
            </div>
          </div>
        </div>
      <div class="col-3">
        <button class="btn btn-primary" @click="$refs.elModalUpload.show()">
          <i class="fa fa-file-upload"></i>
          Subir
        </button>
      </div>
    </div>
    <app-modal-basic ref="elModalUpload">
      <PatientForm ref="elPatientForm" @submitted="reload(); $refs.elModalUpload.hide();"></PatientForm>
    </app-modal-basic>
  </div>
</template>
<script>
import {DentalClinicService} from "../service.js";
import PatientForm from "../patient-files/Form.vue";
// script content
export default {
  name: "PatientFile",
  components: {
    PatientForm,
  },

  // directives

  props: {
    client_id: {},
  },

  data: () => ({
    list: [],
    server_url: process.env.VUE_APP_API_URL,
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    this.reload();
  },

  methods: {
    async reload() {
      var res = await DentalClinicService.getPatientFiles({
        client_id: this.client_id,
      });
      this.list = res.data;
    }
    //
    //
  }
};
</script>
<style scoped>
</style>

